import REQ from "../app/REQ";
import {genMap, upWFoundById} from "../utils/xyhUtils"
import {mapGetters, mapState} from "vuex";
import {DOC_TYPE, DOC_ROLE} from "@/consts"
import docOptions from "./docOptions"
import {wordViewUrl} from "@/doc/consts"
import dlgFoot from "@/generalComs/dlgFoot.vue"
import elDragDialog from "@/directive/el-drag-dialog";
import ADMREQ from "@/app/ADMREQ";
const {DocPathsSMap} = docOptions

export default {
	name: "libDocPrjt",
	components:{ dlgFoot, },
	directives: { elDragDialog },
	props: ['pid'],

	data() {
		return {
			docPaths:DocPathsSMap,
			prjt: [],
			docs:[],

			checks: {},
			isBatch:false,
			docsToCopy:[],
			addedDocs:{},
			filterWord1:'',
			filterWord2:'',
			order:{
				num:true,
			},
			sortingProperty:'',
			docType:'unAnnex',
			roles: [],
			dept:'',
			replaces:[{from:'',to:''}],
			toPrjtId:'',
			toPrjts:[],
			toDocs:[],
			depts:[],
		};
	},

	computed: {
		...mapState({
			orgId: ({ curOrg }) => curOrg?.id,
			myId: ({curStaff}) => curStaff?.id,
		}),
		...mapGetters(['amIAdmin']),

		displayDocs(){
			let {filterWord2, docType, order, docs, dept} = this
			if(filterWord2.length>0) docs = this.docs.filter(it=>it.name.toLowerCase().includes(filterWord2.toLowerCase()) || it.num.toLowerCase().includes(filterWord2.toLowerCase()))
			if(docType) docs = docs.filter(it => docType == 'unAnnex' ? it.type != DOC_TYPE.annex:it.type == docType)
			if(dept) docs = docs.filter(it=>it.deptId == dept.id)
			for(let i in order){
				if(order[i]) {
					docs.sort((doc1, doc2) => doc1[i].toLowerCase().localeCompare(doc2[i].toLowerCase()))
				}else {
					docs.sort((doc1, doc2) => doc2[i].toLowerCase().localeCompare(doc1[i].toLowerCase()))
				}
			}
			return docs
		},

		allSeled() {return this.displayDocs.every(it=>this.checks[it.id]);},

		isIndeterminate() { return  !(this.allSeled || this.displayDocs.every(it=>!this.checks[it.id]))},
	},

	created() {
		this.getLibDocPrjt()
		this.getDocPrjts()
	},

	methods: {
		getLibDocPrjt() {
			let {pid} = this
			this.req2Data(REQ.docPrjtInLib, pid, null,({docs,tplts,prjt,depts,}) => {
				prjt.sopTpltN = tplts.find(it=>it.id == prjt.sopTpltId) ? tplts.find(it=>it.id == prjt.sopTpltId).name : ''
				prjt.policyTpltN = tplts.find(it=>it.id == prjt.policyTpltId) ? tplts.find(it=>it.id == prjt.policyTpltId).name : ''
				prjt.manualTpltN = tplts.find(it=>it.id == prjt.manualTpltId) ? tplts.find(it=>it.id == prjt.manualTpltId).name : ''
				upWFoundById(docs,tplts,'tpltId',(doc,tplt)=>doc.tpltN = tplt.name)
				upWFoundById(docs, depts, 'deptId', (doc, dept)=> doc.deptN = dept.name)
				docs.forEach(it=> {
					if(!it.type) it.type = DOC_TYPE.sop
					it.canPreview = it.tpltId || (it.type == DOC_TYPE.sop && prjt.sopTpltId) || (it.type == DOC_TYPE.policy && prjt.policyTpltId) || (it.type == DOC_TYPE.manual && prjt.manualTpltId)
					it.url = ((it.type == DOC_TYPE.annex || it.type == DOC_TYPE.file) && it.file) ? it.file:XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + it.id + '&orgId=CommonRes&t=' + new Date().getTime()
				})
			})
		},

		getDocPrjts(){
			this.req2Data(REQ.myDocPrjts, null, {prjts: 'toPrjts',},({prjts,prjtStaffs})=>{
				upWFoundById(prjtStaffs, prjts, "prjtId", (prjtStaff, prjt) => {
					if(prjtStaff.staffId == this.myId && (prjtStaff.role == DOC_ROLE.PM || prjtStaff.role == DOC_ROLE.author)) {
						prjt.canAddDoc = true
					}
				})
				this.toPrjts = prjts.filter(it=>it.canAddDoc)
			})
		},

		getDocCrRolesOfPrjt(){
			let {pid} = this
			this.req2Data(REQ.docCrRolesOfPrjt, {prjtId: pid}, null)
		},

		getDocs(prjtId){
			this.req2Data(REQ.docPrjt, prjtId, {docs:'toDocs'},()=> {
			})
		},

		search() {
			this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
		},

		sorted(propertyN){
			this.sortingProperty = propertyN
			let order = !this.order[propertyN]
			delete this.order[propertyN]
			this.$set(this.order,propertyN,order)
		},

		typeFilter(type){
			this.$set(this,'docType',type)
		},

		deptFilter(dept){
			this.$set(this,'dept',dept)
		},

		CheckedChange() {
			this.$forceUpdate()
		},


		preview(url){
			window.open(wordViewUrl + encodeURIComponent(url))
		},

		batchSelection(){
			let {allSeled, isIndeterminate, displayDocs, addedDocs} = this
			let s = !(isIndeterminate || allSeled)
			displayDocs.forEach(it => {
				if(!addedDocs[it.id]) this.$set(this.checks, it.id, s)
			})
		},

		insert(arr, idx, obj) {
			arr.splice(idx + 1, 0, obj)
		},

		strikeOut(arr, idx) {
			arr.splice(idx, 1)
		},

		swap(arr, idx) {
			let t = arr[idx]
			arr.splice(idx + 2, 0, t)
			arr.splice(idx, 1)
		},

		addDocsToCopy(){
			let {displayDocs, docsToCopy, checks, addedDocs} = this
			displayDocs.forEach(it=> {
				if (checks[it.id] && !docsToCopy.find(item=>item.id == it.id)) {
					docsToCopy.push({id: it.id, name: it.name, num: it.num, Name: it.name, Num: it.num})
					checks[it.id] = false
					addedDocs[it.id] = true
				}
			})
		},

		remove(idx) {
			let {docsToCopy, addedDocs} = this
			addedDocs[docsToCopy[idx].id] = false
			docsToCopy.splice(idx, 1)
		},

		clone(){
			let {orgId,toPrjtId,replaces,docsToCopy,toDocs} = this
			let docs = docsToCopy.map(it=> {return {id: it.id, name: it.name, num: it.num}})
			let replaceWords = []
			if(this.chk2msg(toPrjtId,'请选择目标项目',docs.length>0,'请选择转移文档')) return
			replaceWords = replaces.filter(replace=>replace.from)
			if(!docs.find((doc,idx) => {
				if (toDocs.find(toDoc => doc.name == toDoc.name || doc.num == toDoc.num)) {
					this.$message('文档' + doc.name + '在目标项目中存在名称或编号的重复')
					return true
				}
			}))
				this.$confirm('确认开始拷贝吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.req2Data(REQ.cloneCommonDocs,{replaces:replaceWords,fromOId:orgId,toOId:orgId,toPrjtId,docs},{},()=>{
						this.getDocs(toPrjtId)
						this.docsToCopy = []
						this.ok('拷贝成功')
					})
				}).catch(() => {
				});
		},
	},

};
